import React, { useEffect, useState } from "react";
import RoutePath from "./../../routes";
import TopNavbar from "../../common/upper-navbar/top-navbar";
import Navbar from "./../../common/navbar/Navbar";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, openLogin }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        marginTop: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        }),
    }),
);


function Approot({ admin, isLoggedIn }) {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [openLogin, setLoging] = useState(false);
    const [inboxOpen, setInboxOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.pathname === "/login") {
            setLoging(true);
            setOpen(false);
            setInboxOpen(false);
        } else if (location?.pathname === "/forgot-password") {
            setInboxOpen(true);
            setLoging(false);
            setOpen(false);
        } else if (location?.pathname?.split("/")?.[1] === "reset-password") {
            setInboxOpen(true);
            setLoging(false);
            setOpen(false);
        }
        else {
            setLoging(false);
            setInboxOpen(false);
            setOpen(true);
        }
    }, [location])

    useEffect(() => {
        if (isLoggedIn === 'false') {
            navigate("/login");
        }
    }, [isLoggedIn]);


    return (
        <Box>
            <TopNavbar isLoggedIn={isLoggedIn} />
            <Box >
                {openLogin || inboxOpen ? null :
                    (<Navbar open={open} setOpen={setOpen} admin={admin}   />)}
                <Box flexGrow={1} minHeight={inboxOpen ? "" : "100vh"}>
                    <Box sx={{ marginTop: openLogin ? "0px" : "40px", }}>
                        {openLogin ? (
                            <RoutePath />
                        ) : (
                            <Main open={open}>
                                <RoutePath />
                            </Main>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Approot;
