import React, { lazy, Suspense } from "react";
import { ProtectedRoute } from './app/protected.route';
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

const LoginContainer = lazy(() => import('./view/login/login.container'));
const DashboardContainer = lazy(() => import('./view/dashboard/dashboard.container'));
const ForgotPasswordContainer = lazy(() => import('./view/forgot-password/forgot-password.container'));
const ResetPasswordContainer = lazy(() => import('./view/reset-password/reset-password.container'));
const BookingStatusContainer = lazy(() => import('./view/masters/booking-status/booking-status.container'));
const StateContainer = lazy(() => import('./view/masters/state/state.container'));
const CountryContainer = lazy(() => import('./view/masters/country/country.container'));
const PropertiesAddContainer = lazy(() => import('./view/properties-add/properties-add.container'));
const ContentContainer = lazy(() => import('./view/content/content.container'));
const CityContainer = lazy(() => import('./view/masters/city/city.container'));
const AmenitiesTypeContainer = lazy(() => import('./view/masters/amenities-type/amenitiesType.container'));
const AmenitiesContainer = lazy(() => import('./view/masters/amenities/amenities.container'));
const RoomAmenitiesContainer = lazy(() => import('./view/masters/room-amenities/room-amenities.container'));
const LanguageContainer = lazy(() => import('./view/masters/language/language.container'));
const ListingTypeContainer = lazy(() => import('./view/masters/listing-type/listing-type.container'));
const PropertyTypeContainer = lazy(() => import('./view/masters/property-type/property-type.container'));
const RoomTypeContainer = lazy(() => import('./view/masters/room-type/room-type.container'));
const PropertyOwnersContainer = lazy(()=> import('./view/property-owners/property-owners.container'));
const CustomersContainer = lazy(()=> import('./view/customers/customers.container'));
const BookingPropertyTableContainer = lazy(()=> import('./view/booking-table/bookingTable.container'));
const BookingCalendarContainer = lazy(()=> import('./view/booking-calendar/booking-calendar.container'));
const FaqContainer = lazy(()=> import('./view/masters/faq/faq.container'));
const TagContainer = lazy(()=> import('./view/masters/tags/tags.container'));
const PageContainer = lazy(()=> import('./view/masters/pages/page.container'));
const PropertyContainer = lazy(()=> import('./view/property/property.container'));
const ProfileSettingContainer = lazy(()=> import('./view/setting/profile-setting/profile-setting.container'));
const RoleContainer = lazy(()=> import('./view/setting/role/role.container'));
const SubAdminContainer = lazy(()=> import('./view/users/sub-admin/sub-admin.container'));
const ListPropertyContainer = lazy(()=> import('./view/list-property/list-property.container'));
const ContactUsContainer = lazy(()=> import('./view/contact-us/contactus-table.container'));
const InboxContainer = lazy(()=> import('./view/inbox/inbox.container'));
const UpdatePasswordContainer = lazy(()=> import('./view/setting/password-setting/password-setting.container'));
const RazorpaySettingContainer = lazy(()=> import('./view/razorpay-setting/razorpay-setting.container'));
const DocumentContainer = lazy(()=> import('./view/document/document.container'));
const BookingCalendarMultiContainer = lazy(()=> import('./view/booking-calendar-multi/booking-calendar-multi.container'));

const RoutesPath = () => {
    return (
        <Box>
            <Routes>
                <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<>...</>}><ProtectedRoute><DashboardContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-status" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingStatusContainer /></ProtectedRoute></Suspense>} />
                <Route path="/forgot-password" element={<Suspense fallback={<>...</>}><ForgotPasswordContainer /></Suspense>} />
                <Route path="/reset-password/:token" element={<Suspense fallback={<>...</>}><ResetPasswordContainer /></Suspense>} />
                <Route path="/state" element={<Suspense fallback={<>...</>}><ProtectedRoute><StateContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property/create" element={<Suspense fallback={<>...</>}><ProtectedRoute></ProtectedRoute><PropertiesAddContainer /></Suspense>} />
                <Route path="/content" element={<Suspense fallback={<>...</>}><ProtectedRoute><ContentContainer /></ProtectedRoute></Suspense>} />
                <Route path="/country" element={<Suspense fallback={<>...</>}><ProtectedRoute><CountryContainer /></ProtectedRoute></Suspense>} />
                <Route path="/city" element={<Suspense fallback={<>...</>}><ProtectedRoute><CityContainer /></ProtectedRoute></Suspense>} />
                <Route path="/amenities-type" element={<Suspense fallback={<>...</>}><ProtectedRoute><AmenitiesTypeContainer /></ProtectedRoute></Suspense>} />
                <Route path="/amenities" element={<Suspense fallback={<>...</>}><ProtectedRoute><AmenitiesContainer /></ProtectedRoute></Suspense>} />
                <Route path="/room-amenities" element={<Suspense fallback={<>...</>}><ProtectedRoute><RoomAmenitiesContainer /></ProtectedRoute></Suspense>} />
                <Route path="/language" element={<Suspense fallback={<>...</>}><ProtectedRoute><LanguageContainer /></ProtectedRoute></Suspense>} />
                <Route path="/listing-type" element={<Suspense fallback={<>...</>}><ProtectedRoute><ListingTypeContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property-type" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyTypeContainer /></ProtectedRoute></Suspense>} />
                <Route path="/room-type" element={<Suspense fallback={<>...</>}><ProtectedRoute><RoomTypeContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property-owners" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyOwnersContainer /></ProtectedRoute></Suspense>} />
                <Route path="/customers" element={<Suspense fallback={<>...</>}><ProtectedRoute><CustomersContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-table" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingPropertyTableContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-calendar" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingCalendarContainer /></ProtectedRoute></Suspense>} />
                <Route path="/faq" element={<Suspense fallback={<>...</>}><ProtectedRoute><FaqContainer /></ProtectedRoute></Suspense>} />
                <Route path="/tag" element={<Suspense fallback={<>...</>}><ProtectedRoute><TagContainer /></ProtectedRoute></Suspense>} />
                <Route path="/page" element={<Suspense fallback={<>...</>}><ProtectedRoute><PageContainer /></ProtectedRoute></Suspense>} />
                <Route path="/profile-setting" element={<Suspense fallback={<>...</>}><ProtectedRoute><ProfileSettingContainer /></ProtectedRoute></Suspense>} />
                <Route path="/role" element={<Suspense fallback={<>...</>}><ProtectedRoute><RoleContainer /></ProtectedRoute></Suspense>} />
                <Route path="/sub-admin" element={<Suspense fallback={<>...</>}><ProtectedRoute><SubAdminContainer /></ProtectedRoute></Suspense>} />
                <Route path="/list-property" element={<Suspense fallback={<>...</>}><ProtectedRoute><ListPropertyContainer /></ProtectedRoute></Suspense>} />
                <Route path="/contact-us" element={<Suspense fallback={<>...</>}><ProtectedRoute><ContactUsContainer /></ProtectedRoute></Suspense>} />
                <Route path="/inbox" element={<Suspense fallback={<>...</>}><ProtectedRoute><InboxContainer /></ProtectedRoute></Suspense>} />
                <Route path="/update-password" element={<Suspense fallback={<>...</>}><ProtectedRoute><UpdatePasswordContainer /></ProtectedRoute></Suspense>} />
                <Route path="/razorpay-setting" element={<Suspense fallback={<>...</>}><ProtectedRoute><RazorpaySettingContainer /></ProtectedRoute></Suspense>} />
                <Route path="/documents" element={<Suspense fallback={<>...</>}><ProtectedRoute><DocumentContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-calendar-multi" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingCalendarMultiContainer /></ProtectedRoute></Suspense>} />
            </Routes>
        </Box>
    )
}

export default RoutesPath;