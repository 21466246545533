import { HashRouter } from "react-router-dom";
import AppRootContainer from "../shared/app-root/app-root-container";
import { Provider } from "react-redux";
import store from "./store";
import theme from "../theme";
import { CssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Box>
      <ToastContainer style={{ zIndex:'99996'}}/>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <HashRouter>
            <AppRootContainer />
          </HashRouter>
        </Provider>
      </ThemeProvider>
    </Box>

  );
}

export default App;
