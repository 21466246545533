import React, { useState,useEffect } from 'react';
import {
    Box,
    Container,
    IconButton,
    Stack,
    Typography,
    Tooltip,
    Avatar
} from '@mui/material';
import LogoImg from '../../assests/logo.png';
import MenuDrawer from './components/menu-dropdown';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';


function TopNavbar({ isLoggedIn }) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = (event) => {
        event.stopPropagation();
    };

 

    return (
        <Box sx={{
            display: 'block',
            py: 1,
            borderBottom: '1px solid lightGray',
            bgcolor: '#FAFAF9',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1301,
            width: 1,
            pr: 2,

        }}
            onScroll={handleScroll}
        >
            <Container maxWidth="xlg">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <IconButton sx={{ borderRadius: '10px', px: 2 }} onClick={() => navigate("/property")}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={LogoImg}
                                alt="Login Logo"
                                width={20}
                            />
                            <Typography
                                ml={1}
                                color={'black'}
                                fontSize={14}
                                fontWeight={500}
                            >JadeCaps</Typography>
                        </Box>
                    </IconButton>
                    <Stack
                        direction={'row'}
                        gap={2}
                        alignItems={'center'}
                        display={isLoggedIn ? 'flex' :'none'}
                    >
                        <Typography
                            fontWeight={500}
                            fontSize={14}
                            sx={{
                                '&:hover': {
                                    color: 'black',
                                    cursor: 'pointer'
                                }
                            }}
                        >Dashboard</Typography>

                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2,p:0 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>
                                    <PersonIcon sx={{width:'100%'}}/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <MenuDrawer
                            open={open}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                        />
                    </Stack>
                </Box>
            </Container>
        </Box>
    )
}

export default TopNavbar