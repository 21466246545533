import * as actionType from "./bookingActionType";

const initialState = {
  isLoading: false,
  booking: null,
  bookingConfirm: false,
  bookings: [],
  calendarBookings: [],
  calendarMultiBookings: [],
  multiCount: 0
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookings: action.payload ? action.payload.data : false
            };
        case actionType.CANCEL_BOOKING:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CANCEL_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CANCEL_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.MODIFY_BOOKING:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.MODIFY_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.MODIFY_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.CONFIRM_BOOKING:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CONFIRM_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CONFIRM_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.CREATE_BOOKING:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_RENTAL_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_RENTAL_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_RENTAL_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookings: action.payload ? action.payload.data : false
            };
        case actionType.FETCH_CALENDAR_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_CALENDAR_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_CALENDAR_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                calendarBookings: action.payload ? action.payload.data : []
            };
        case actionType.FETCH_MULTI_CALENDAR_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_MULTI_CALENDAR_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_MULTI_CALENDAR_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                calendarMultiBookings: action.payload ? action.payload.data : [],
                multiCount: action.payload ? action.payload.data.properties?.count : 0
            };
        default:
            return state;
    }
}